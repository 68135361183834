$grey-light: #FAFAFA;
$grey-darker: #9B8B7D;
$grey-dark: #333333;
$color-light: #ffeede; 
$color-lighter:#FFDAB9;
$color-medium: #f7c190;
$color-darker: #cf8746;
$color-dark: #BD6E24;
$red-medium: #e74c3c;
$red-light: #ffcccb;
$red-dark: #440000;
$green-medium: #53e73c;
$green-light: #d8ffcb;
$green-dark: #134400;