@use '../variables' as v;

.page_and_news {
    display: flex;
}

.page {
    box-sizing: border-box;
    min-height: 86vh;
    background-color: v.$grey-light;
    border-radius: 1rem;
    padding: 1rem 2rem;

    &.no-min-height {
        min-height: auto;
    }

    &.widther {
        width: 100%;
    }
}

.news_boxes__box {
    background-color: v.$grey-light;
    border-radius: 1rem;
    padding: 1rem 1rem;
    margin: 1rem;
    border-color: v.$color-lighter;
    border-style: solid;
    box-shadow: v.$color-light 0px 0px 15px 10px;
    width: 300px;
    max-height: 85vh;

    &.scroll {
        overflow-y: scroll;
    }
}


.href {
    text-decoration: underline;
    color: v.$color-dark;

    &hover {
        cursor: pointer;
    }

    .icon {
        vertical-align: bottom;
    }
}

@media only screen and (min-width: 600px) {
    .page {
        width: 80vw;
        margin: 1rem;
        border-color: v.$color-lighter;
        border-style: solid;
        box-shadow: v.$color-light 0px 0px 15px 10px;
    }
}

@media only screen and (min-width: 1200px) {
    .page {
        width: 1000px
    }
}

@media only screen and (max-width: 600px) {
    .page {
        width: 100vw;
    }
}

@media only screen and (max-width: 1500px) {
    .news_boxes {
        display: none;
    }
}