@use '../../variables' as v;

.header {
    position: sticky;
    top: 0;
    z-index: 10;
    height: 3rem;
    width: 100vw;
    background-color: v.$color-lighter;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header__logo {
        height: 2rem;
        padding-left: 2rem;
        cursor: pointer;

        img {
            max-height: 100%;
        }
    }

    .header__big_menu {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    .header__login {
        margin-right: 1rem;
        padding: 0 10px;
        height: 100%;
        display: flex;
        flex-direction: row;
        font-size: larger;
        align-items: center;

        .header__login__icon {
            color: v.$color-darker;
        }

        .header__login__text {
            padding-left: 5px;
        }

        &:hover {
            background-color: v.$color-medium;
            cursor: pointer;
        }
    }

    .header__hamburger {
        display: none;
        flex-direction: column;
        justify-content: space-around;
        height: 35px;
        cursor: pointer;
        margin-right: 2rem;

        &.open {
            .header__hamburger__second {
                opacity: 0;
            }

            .header__hamburger__first {
                transform: rotate(45deg);
                transform-origin: left;
            }

            .header__hamburger__third {
                transform: rotate(-45deg);
                transform-origin: left;
            }
        }
    }

    .header__hamburger span {
        width: 33px;
        height: 3px;
        margin: 2px 0;
        background-color: v.$color-darker;
    }
}

.small_menu {
    display: flex;
    flex-direction: column;
    z-index: 10;
    top: 3rem;
    opacity: 0.9;
    position: sticky;
    background-color: v.$color-lighter;
    width: 100%;
    .menu_item {
        width:100%;
        text-align:center;
    }
}

@media only screen and (min-width: 600px) {
    .header__big_menu {
        display: flex !important;
    }

    .header__login {
        display: flex !important;
    }

    .header__hamburger {
        display: none !important;
    }

    .small_menu {
        display: none !important
    }
}

@media only screen and (max-width: 600px) {
    .header__big_menu {
        display: none !important;
    }

    .header__login {
        display: none !important;
    }

    .header__hamburger {
        display: flex !important;
    }

    .small_menu {
        display: flex !important
    }
}
