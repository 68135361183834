@use '../../variables' as v;

.teams_list {
    display: flex;
    flex-direction: column;

    &__team {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;

        &__name {
            font-size: larger;
            font-weight: 500;
            color: v.$color-dark;
            display: flex;
            flex-direction: row;

            &__badge {
                color: v.$color-medium;
                padding-right: 5px;
            }
        }

        &__players {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &__player {
                padding-right: 5px;
            }
        }
    }
}

