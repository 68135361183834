@use '../../../variables' as v;

.menu_item {
    display: flex;
    flex-direction: row;
    font-size: larger;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    justify-content: center;

    &:hover {
        background-color: v.$color-medium;
        cursor: pointer;
    }

    &__icon {
        padding-right: 5px;
        color: v.$color-darker;
    }

    &__text {
        padding: 3px 0;
    }
}
