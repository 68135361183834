@use '../../variables' as v;

.text_input {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    &__label {
        font-weight: 500;
        margin-bottom: 8px;
        width: 15rem;
        padding-right: 10px;
        display: flex;
        align-items: center;

        &__required {
            color: v.$red-medium;
            margin-left: 4px;
        }
    }

    &__input {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        &__text {
            display: flex;
            align-items: center;
            border: 1px solid v.$color-lighter;
            border-radius: 8px;
            padding: 12px 16px;
            transition: border-color 0.3s, box-shadow 0.3s;
            max-width: 25rem;

            &:focus-within {
                border-color: v.$color-darker;
                box-shadow: 0 0 8px rgba(v.$color-darker, 0.2);
            }

            &__input,
            &__textarea {
                flex: 1;
                border: none;
                outline: none;
                font-size: 14px;
                background: transparent;

                &::placeholder {
                    color: #999;
                }
            }

            &__textarea {
                height: 7rem;
            }

            &.invalid,
            &.invalid {
                border-color: v.$red-medium !important;
            }
            

            &__error {
                color: v.$red-medium;
                font-size: 12px;
                margin-top: 4px;
            }
        }
    }
}

    @media only screen and (max-width: 600px) {
        .text_input {
            flex-direction: column;
        }
    }


    .button {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 1rem 0;

        &.secondary {
            justify-content: flex-start;
        }

        &__button {
            background-color: v.$color-medium;
            border: none;
            border-radius: 8px;
            padding: 15px 25px;
            font-size: 16px;
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
            display: flex;
            flex-direction: row;
            align-items: center;

            &__icon {
                padding-left: 10px;
                margin-right: -5px;
                color: v.$color-dark;
            }


            &:hover {
                background-color: v.$color-darker;
            }

            &:disabled {
                background-color: v.$color-lighter;
                cursor: not-allowed;
            }

            &.secondary {
                background-color: v.$color-light;
                border: 1px solid v.$color-darker;
                padding: 10px 25px;

                &:hover {
                    background-color: v.$color-medium;
                }
            }
        }
    }

.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: v.$color-light;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    &:hover input + .checkbox__checkmark {
        background-color: v.$color-lighter;
    }

    &__input:checked + .checkbox__checkmark {
        background-color: v.$color-dark; // barva pozad� p�i za�krtnut�
    }

    &__input:checked + .checkbox__checkmark:after {
        display: block;
    }

    &__checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid v.$color-light;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
