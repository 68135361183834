@use '../../../variables' as v;

.admin_team {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__payment {
        padding-right: 10px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: row;
        }

        &__remove {
            color: v.$red-medium;
        }

        &__add {
            color: v.$green-medium;
        }
    }

    &__unregister {
        padding-right: 10px;
        cursor: pointer;
        color: v.$red-dark;
    }

    &__register {
        padding-right: 10px;
        cursor: pointer;
        color: v.$green-dark;
    }

    &__name {
        padding-left: 10px;
    }

    &__websystem {
        margin-left: 10px;
        cursor: pointer;
    }
}
