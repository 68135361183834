.player {
    &__headline {
        display: flex;
        align-items: center;

        &__icon {
            padding-left: 10px;
            cursor: pointer;
        }
    }
}
