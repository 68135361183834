@use './variables' as v;

.App {
    background-color: whitesmoke;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), url('./resources/images/pozadi_stopy_h.png');
    background-size: 180px 180px;
    background-repeat: repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: v.$grey-dark;
}
