@use '../../variables' as v;

.sub_menu {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__item {
        padding: 0 1rem;
        font-size: larger;
        font-weight: 400;
        cursor: pointer;
        color: v.$color-dark;

        &.selected {
            color: v.$color-medium;
        }

        &:hover {
            font-weight: 600;
        }
    }
}
