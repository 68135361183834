@use '../../variables' as v;

.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid;
    border-radius: 1rem;
    padding: 8px 1rem;
    margin: 5px 5px 2rem 5px;

    &.error {
        background-color: v.$red-light;
        border-color: v.$red-medium;
        color: v.$red-dark;
        box-shadow: v.$red-light 0px 0px 10px 5px;
    }

    &.success {
        background-color: v.$green-light;
        border-color: v.$green-medium;
        color: v.$green-dark;
        box-shadow: v.$green-light 0px 0px 10px 5px;
    }

    &__icon {
        margin-right: 1rem;
    }
}
