@use '../../variables' as v;

.forum_message {
    width: 100%;
    border-radius: 1rem;
    margin: 1rem 0;
    border: 1px solid v.$color-lighter;
    box-shadow: v.$color-light 0px 0px 10px 5px;

    &__headline {
        display: flex;
        flex-direction: row;
        padding: 1rem 0;
        border-bottom: 1px solid v.$color-medium;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        &__name {
            padding: 0 1rem;
            font-weight: 500;
            color: v.$color-dark;
        }

        &__date {
            padding: 0 1rem;
            color: v.$color-dark;
        }

        &__author {
            padding: 0 1rem;
            color: v.$color-dark;
        }
    }

    &__message {
        padding: 0 1rem 1rem 1rem;
    }
}
