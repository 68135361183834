@use '../../variables' as v;

.modal {
    z-index: 100;
    position: fixed; /* Fixed, aby modal p�ekryl celou obrazovku */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; /* Centrumje modal box vertik�ln� i horizont�ln� */

    .modal__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(150, 150, 150, 0.5); /* Polopr�hledn� �ern� pro mlhav� efekt */
        backdrop-filter: blur(5px); /* Rozost�en� pozad� */
    }

    .modal__box {
        position: relative;
        z-index: 101;
        background-color: v.$grey-light;
        height: fit-content;
        border-color: v.$color-lighter;
        border-style: solid;
        box-shadow: v.$color-light 0px 0px 15px 10px;
        border-radius: 1rem;

        .modal__box__headline {
            background-color: v.$color-lighter;
            border-radius: 0.5rem 0.5rem 0 0;
            padding: 10px 1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: larger;

            .modal__box__headline__close {
                color: v.$color-darker;

                &:hover {
                    cursor: pointer;
                    color: v.$color-dark;
                }
            }
        }
    }

    .modal__box__body {
        padding: 1rem;
    }
}


@media only screen and (min-width: 600px) {
    .modal__box {
        width: 500px;
    }
}

@media only screen and (max-width: 600px) {
    .modal__box {
        width: 90%;
    }
}

.href {
    cursor: pointer;
    text-decoration: underline;
}