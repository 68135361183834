@use '../../variables' as v;

.headline {

    h1 {
        width: 100%;
        text-align: center;
        color: v.$grey-darker;
    }
}

.paragraph {
    text-align: justify;
    padding-bottom: 10px;

    &__centered {
        display: flex;
        justify-content: center;
    }

    strong {
        color: v.$color-dark;
        font-weight: 500;
    }
}

.spacer {
    height: 2rem;
}

li::marker {
    color: v.$grey-darker;
}

.select {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__label {
    }

    &__select {
        margin-left: 1rem;
        min-width: 15rem;
        border: 1px solid v.$color-lighter;
        border-radius: 8px;
        padding: 12px 16px;
        transition: border-color 0.3s, box-shadow 0.3s;
        max-width: 25rem;
    }
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -1rem;

    &__stars {
        margin-right: 10px;
    }

    &__label {
        font-size: smaller;
        margin-bottom: 1rem;
    }
}