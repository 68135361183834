@use '../../variables' as v;
.footer {
    width: 100%;
    height: 3rem;
    background-color: v.$color-lighter;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
        text-decoration: none;
        color: green !important;
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 1rem;
        color: v.$color-dark;

        &__icon {
            padding-right: 10px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .footer {
        height: 5rem;
    }
}


@media only screen and (max-width: 450px) {
    .footer {
        height: 7rem;
    }
}