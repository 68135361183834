@use '../../variables' as v;

.statsContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.stats {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    max-width: 100vw;

    .stats__container {
        width: max-content;
    }

    .stats__first_row {
        display: flex;
        flex-direction: row;
        background-color: v.$color-light;
        padding: 5px 0;
        border-bottom: 1px solid v.$color-medium;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .stats__row {
        display: flex;
        flex-direction: row;
        padding: 5px 0;
        border-bottom: 1px solid v.$color-medium;

        &:nth-child(odd) {
            background-color: v.$color-light; /* Sv�tle �ed� pozad� pro ka�d� druh� ��dek */
        }
    }

    .stats__collumn_order {
        width: 40px;
        padding-left: 10px;

        &.surrended {
            background-color: v.$color-medium;
        }
    }

    .stats__collumn_team {
        width: 200px;
        overflow: hidden;

        &.surrended {
            background-color: v.$color-medium;
        }
    }

    .stats__collumn_cypher_time {
        display: flex;
        justify-content: center;
        width: 55px;
        position: relative;
    }

    .stats__collumn_time {
        width: 100px;
        display: flex;
        justify-content: center;
    }
}

.help_normal {
    background-color: dodgerblue;
}


.help_instructions {
    background-color: blueviolet;
}

.help_absolute {
    background-color: indianred;
}

.help_mini {
    background-color: darkkhaki;
}

.stats__collumn_cypher_time:hover .tooltip, .stats__collumn_cypher_time:active .tooltip {
    display: block;
}

.tooltip {
    display: none;
    background-color: #555;
    position: absolute;
    color: v.$color-light;
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    pointer-events: none;
}
/* P�izp�soben� tooltipu pro men�� obrazovky */
@media only screen and (max-width: 600px) {
    .tooltip {
        top: 20px;
        font-size: 12px;
    }
}

.mobile_only{
    display: flex;
}

@media only screen and (min-width: 1501px) {
    .mobile_only {
        display: none;
    }
}
