@use '../../variables' as v;


.help {
    margin: 5px 0;
    align-content: center;

    .hidden {
        background-color: v.$color-light;
        padding: 10px 30px;
        text-align: justify;
        cursor: pointer;
        border-radius: 10px;
    }

    .visible {
        background-color: v.$color-light;
        padding: 10px 20px;
        text-align: justify;
        border-radius: 10px;
        box-shadow: 0 3px 7px rgb(255 200 20 / 50%);
    }
}

.cypher {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .cypher__pdf {
        width: 100%;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .cypher__detail {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__helps {
            margin: 1rem 0;
        }
    }
}
