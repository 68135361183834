@use '../../variables' as v;

.bigLink {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: v.$color-dark;
    font-size: larger;
    font-weight: 600;

    &:hover {
        cursor: pointer;
    }
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    .info__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 20px;
    }

    .info__box__icon {
        margin: 10px;
        color: v.$color-medium;
    }
}

@media only screen and (min-width: 1501px) {
    .news_in_page {
        display: none;
    }
}

