@use '../../../variables' as v;

.user__modal__background {
    z-index: 100;
    position: fixed; /* Fixed, aby modal p�ekryl celou obrazovku */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
}

.user__modal__box {
    display: flex;
    top: 3rem;
    right: 0;
    width: 15rem;
    position: absolute;
    z-index: 101;
    background-color: v.$color-lighter;
    flex-direction: column;
}
