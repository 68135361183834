@use '../../variables' as v;

.teams_contacts {
    &__team {
        margin-bottom: 1rem;

        &__name {
            font-size: larger;
            font-weight: 500;
            color: v.$color-dark;
            display: flex;
            flex-direction: row;

            &__badge {
                color: v.$color-medium;
                padding-right: 5px;
            }
        }

        &__emails {
            display: flex;
            flex-direction: row;

            &__email {
                margin-right: 10px;
            }
        }
    }
}

.admin_team_list_csv {
    cursor: pointer;
    font-size: larger;

    &:hover {
        color: v.$color-dark;
    }

    svg {
        color: v.$color-dark;
        margin-right: 3px;
    }
}

.passwords__team_name {
    font-size: larger;
    font-weight: 500;
    color: v.$color-dark;
    cursor: pointer;
    margin: 5px 0;
}
