@use '../../variables' as v;

.disclaimer {
    color: v.$red-dark;
    font-size: smaller;
}

.payment {
    &__yes {
        color: v.$green-medium;
        font-weight: 600;
    }

    &__no {
        color: v.$red-medium;
        font-weight: 600;
    }
}

.payment_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    .payment_section__qr {
        display: flex;
        flex: 1 1 50%;
    }
}